.purchaseContainer {
  min-height: 100vh;
}

.loading {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.productDesCon {
  display: flex;
  padding: 3rem 7rem;
  gap: 2rem;
}

.productContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.imageContainer {
  display: flex;
}

.imageSlider {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.images {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 275px;
  display: flex;
  align-items: center;
}

.images img {
  width: 100%;
  display: none;
  transition: opacity 0.5s ease-in-out;
}

.images img.active {
  display: block;
}

.radioButtons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.radioButtons input[type="radio"] {
  display: none;
}

.radioButtons label {
  width: 15px;
  height: 15px;
  margin: 0 5px;
  border-radius: 50%;
  background: #ccc;
  cursor: pointer;
  display: inline-block;
}

.radioButtons input[type="radio"]:checked + label {
  background: #333;
}

.othImg {
  width: 7rem;
  height: 7rem;
  margin-bottom: 1rem;
  cursor: pointer;
  background-color: #f3f3f3;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.othImg img {
  width: 100%;
}

.productImg {
  width: 100%;
  height: 500px;
  cursor: pointer;
  background-color: #f3f3f3;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.productImg img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.product-Des {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.productDet {
  display: flex;
  flex-direction: column;
}

.productName {
  font-size: 2rem;
  margin-bottom: 0.75rem;
}

.shortDes {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}

.price {
  font-size: 1.75rem;
  color: #007fff;
  margin-bottom: 0.5rem;
}

.warn {
  color: #d2122e;
}

.colorVariants {
  display: flex;
  gap: 10px;
}

.colorVariant {
  font-size: 0.9rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.colorVariant .dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-bottom: 7px;
}

.stockValue {
  margin: 5px 0 10px 0;
  font-size: 20px;
}

.outStock {
  margin: 5px 0 10px 0;
  font-size: 20px;
  color: #d2122e;
}

.selected {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
}

.coolInput {
  display: flex;
  flex-direction: column;
  border: 1px solid #4596f3;
  border-radius: 4px;
  margin-bottom: 1.5rem;
}

.availDiscount {
  padding: 0.5rem;
}

.buyCart {
  text-decoration: none;
}

.buy-Now {
  color: #fff;
  background: #4596f3;
  text-transform: uppercase;
  padding: 1rem;
  text-align: center;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: 700;
}

.collapsible {
  background-color: #f1f1f1;
  width: 100%;
  border: none;
  text-align: left;
  font-size: 15px;
  margin: 1rem auto;
}

.collapsible-button {
  cursor: pointer;
  padding: 18px;
  width: 100%;
  outline: none;
}

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #f9f9f9;
}

.collapsible.active .collapsible-button {
  background-color: #ddd;
}

.collapsible.active .content {
  display: block;
}

/* Responsive Design for Mobile Devices */
@media only screen and (max-width: 600px) {
  .productImg {
    height: 300px;
  }

  .productDesCon {
    flex-direction: column;
    padding: 2rem 1rem;
  }

  .product-Des {
    width: auto;
  }

  .productContainer {
    width: auto;
  }
}

/* Responsive Design for Tablets */
@media only screen and (min-width: 601px) and (max-width: 900px) {
  .productDesCon {
    padding: 2rem 3rem;
  }

  .product-Des {
    width: 100%;
  }

  .productContainer {
    width: 100%;
  }

  .productImg {
    height: 400px;
  }

  .productName {
    font-size: 1.75rem;
  }

  .shortDes {
    font-size: 1.15rem;
  }

  .price {
    font-size: 1.5rem;
  }
}

/* Responsive Design for Small Laptops */
@media only screen and (min-width: 901px) and (max-width: 1200px) {
  .productDesCon {
    padding: 2rem 4rem;
  }

  .product-Des {
    width: 50%;
  }

  .productContainer {
    width: 50%;
  }

  .productImg {
    height: 450px;
  }

  .productName {
    font-size: 1.85rem;
  }

  .shortDes {
    font-size: 1.2rem;
  }

  .price {
    font-size: 1.65rem;
  }
}
