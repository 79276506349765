.orderContainer {
  padding: 20px;
  min-height: 100vh;
}

.oHeading {
  text-align: center;
  margin-bottom: 20px;
}

.emptyOrderMessage {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
}

.invBtn {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 0.8); */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
