.footer {
    margin-top: auto;
    background-color: #333232;
    color: #e5e5e5;
    padding: 1rem;
}

.footerContainer {
    display: flex;
    justify-content: space-around;
    padding: 1rem;
}

.kjarName {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
}

.tag {
    text-decoration: none;
    color: #e5e5e5;
}

.tag:hover {
    text-decoration: underline;
    text-decoration-color: #e5e5e5;
}

.glassFooter {
    display: flex;
    flex-direction: column;
    line-height: 2rem;
    cursor: pointer;
}

.horizontalLine {
    border: 1px solid #e5e5e5;
    margin: 0rem 1rem;
}

.copyRight {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}
