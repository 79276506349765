.errorMsg {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.glassContainer {
  padding: 30px 40px;
  min-height: 100vh;
}

.storeCon {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.linkPage {
  text-decoration: none;
  color: black;
}

.product {
  display: flex;
  flex-direction: column;
  border: 1px solid #f3f3f3;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
}

.product:hover {
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.productImage {
  border-radius: 4px;
  text-align: center;
  margin-bottom: 7px;
  position: relative;
  height: 300px;
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
    transition: transform 0.3s ease-in-out;
  }

  img:hover {
    transform: scale(1.1);
  }
}

.productDetails {
  padding: 10px;
  font-size: 20px;
}

.productDes {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buyNow {
  border-radius: 8px;
  background-color: rgb(22 163 74);
  padding: 12px;
  color: white;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}

.buyNow:hover {
  background-color: #24a0ed;
}

.horizontal-Line {
  border-top: 1px solid grey;
  margin: 0 2rem 1rem 2rem;
}

.similarTxt {
  font-weight: 700;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 10px;
}

/* Responsive Design */

@media only screen and (max-width: 768px) {
  .storeCon {
    grid-template-columns: repeat(3, minmax(auto, 187px));
    gap: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .glassContainer {
    padding: 14px;
  }

  .storeCon {
    grid-template-columns: repeat(2, minmax(auto, 187px));
    gap: 10px;
  }

  .productImage {
    height: 200px;
  }
}
