.form {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100vh;
    z-index: 201;
    background-color: rgba(0, 0, 0, .8);
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10% auto;
    position: relative;
    z-index: 301;
    height: auto;
}

.loginBox{
    width: 600px;
    border-radius: 10px;
    padding: 20px;
    background-color: #ffffff;
}

.login {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    margin-bottom: 10px;
}

.closeLogin {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    font-size: 25px;
}

.form button {
    align-self: flex-end;
}

.flex-column > label {
    color: #151717;
    font-weight: 600;
}

.inputForm {
    border: 1.5px solid #ecedec;
    border-radius: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    transition: 0.2s ease-in-out;
}

.inputField {
    border: none;
    width: 100%;
    border-radius: 20px;
    height: 90%;
    font-size: 15px;

}

.inputField:focus {
    outline: none;
}

.inputForm:focus-within {
    border: 1.5px solid #2d79f3;
}

.flex-column {
    margin: 10px 0px;
}

.error {
    margin-top: 10px;
    color: #d2122e;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    margin: 10px 0px;
}

.flex-row > div > label {
    font-size: 14px;
    color: black;
    font-weight: 400;
}

.span {
    font-size: 14px;
    margin-left: 5px;
    color: #2d79f3;
    font-weight: 500;
    cursor: pointer;
}

.button-submit {
    margin: 20px 0 10px 0;
    background-color: #151717;
    border: none;
    color: white;
    font-size: 15px;
    font-weight: 500;
    border-radius: 10px;
    height: 50px;
    width: 100%;
    cursor: pointer;
}

.button-submit:hover {
    background-color: #252727;
}

.p {
    text-align: center;
    color: black;
    font-size: 14px;
    margin: 5px 0;
}

.btn {
    margin-top: 10px;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    gap: 10px;
    border: 1px solid #ededef;
    background-color: white;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.btn:hover {
    border: 1px solid #2d79f3;;
}

/* Login.css */
.login-modal .ant-modal-content {
    border-radius: 8px;
  }
  
  .login-modal .ant-modal-header {
    border-bottom: none;
  }
  
  .login-modal .ant-modal-title {
    text-align: center;
    width: 100%;
    font-size: 24px;
  }
  
  .login-modal .ant-form-item {
    margin-bottom: 16px;
  }
  
  .error {
    color: red;
    margin-bottom: 16px;
    text-align: center;
  }
  

/*Responsive Design*/

@media only screen and (max-width: 768px) {
    .loginContainer {
        margin: 10%;
    }
}