.cartContainer {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  min-height: 100vh;
}

.cItems {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.leftCon {
  display: flex;
  flex-direction: column;
}

.myCart {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.cartItem {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.itemImg {
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  vertical-align: center;
  align-items: center;
  width: 125px;
  height: 125px;
  border-radius: 50%;

  img {
    width: 100px;
    height: auto;
  }
}

.pName {
  flex: 2;
  font-size: 18px;
  font-weight: bold;
  margin-left: 20px;
}

.pColor {
  flex: 1;
  font-size: 16px;
}

.quantityControls {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.quantityControls button {
  padding: 5px 10px;
}

.quantity {
  margin: 0 10px;
}

.pPrice {
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  margin-right: 20px;
}

.removeItem button {
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
}

.summaryCon {
  flex: 1;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 20px;
  height: 220px;
  margin-top: 47px;
}

.pSummary {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.pLine {
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
}

.pdetail {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.submitBt {
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitButton {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.emptyCartMessage {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}

/*Responsive Design*/

@media only screen and (max-width: 768px) {
  .cartContainer {
    flex-direction: column;
  }

  .cItems {
    flex: 0;
  }

  .leftCon {
    display: flex;
    justify-content: center;
  }

  .summaryCon {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    /* height: 220px; */
    margin: 30px 0;
  }
}

@media only screen and (max-width: 680px) {
  .cartItem {
    flex-direction: column;
    gap: 7px;
  }

  .cItems {
    flex: 0;
  }

  .itemImg {
    width: 150px;
    height: 150px;

    img {
      width: 125px;
      height: auto;
    }
  }
}
