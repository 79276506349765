.navContainer {
  width: 100%;
  position: sticky;
  z-index: 1000;
  top: 0;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.navHead {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(55 65 81);
  color: rgb(255 255 255);
  font-size: 20px;
  line-height: 28px;
  padding: 20px 0;

  img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }
}

.mobileNum a {
  text-decoration: none;
  color: rgb(255 255 255);
}

.navBottom {
  width: 100%;
  display: flex;
  background-color: wheat;
}

.glassType {
  width: 70%;
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  line-height: 28px;
  padding: 20px 0;
}

.anchorTag {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.active {
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: white;
}

.anchorTag:hover {
  text-decoration: underline;
  text-decoration-color: white;
}

/* Dropdown styles */
.dropdown {
  position: relative;
  display: inline-block;
  margin: auto;
}

.dropdown-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  cursor: pointer;
  background-color: white;
  border: 1px solid black;
  border-radius: 20px;
  padding: 5px 10px;
  gap: 5px;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown-content {
  margin-top: 10px;
  border-radius: 2px;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.hLine {
  border: 1px solid #e5e5e5;
  margin: 0rem 1rem;
}

.personalBox {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  margin: auto;
}

.nav-item {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.arrow-down {
  height: 0px;
  width: 0px;
  border: none;
  border-top: 5px solid #000000;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
}

.loginDd {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 180px;
  border-radius: 5px;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.loginDd a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.lItem {
  padding: 7px;
  font-size: 20px;
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.lLine {
  width: 90%;
  border: 1px solid #e5e5e5;
  margin: 0 1rem;
}

/* Responsive Design */

@media only screen and (max-width: 768px) {
  .navHead {
    font-size: 16px;
  }

  .navBottom {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }

  .personalBox {
    padding-right: 15px;
  }
}
