.login-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .login-not-found h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .login-not-found button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .login-not-found button:hover {
    background-color: #0056b3;
  }
  