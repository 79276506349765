.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.orderDetCon {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 500px;
}

.formContainer {
    width: 100%;
}

.formHeader {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
}

.orderForm {
    display: grid;
    gap: 15px;
}

.gridTwoCols {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.inputLabel {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.inputText {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.submitButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
}

.submitButton:hover {
    background-color: #0056b3;
}
